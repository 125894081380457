import React from "react";
import FadeIn from "../FadeIn/FadeIn";
import "./Footer.css";

const Footer = () => {
	return (
		<FadeIn>
			<footer className="footer-container">
				<div className="footer-wrap">
					<div className="footer-top-divider">
						<div className="footer-divider"></div>
					</div>
					<h2 className="footer-title">
						Anmol
						<br />
						Mathias
					</h2>
					<div className="footer-links">
						<a href="mailto: anmolmathias@gmail.com">Contact</a>
						<a
							href="https://github.com/anmolmathias"
							rel="noopener noreferrer"
							target="_blank"
						>
							Github
						</a>

						<a
							href="https://linkedin.com/in/anmolmathias"
							rel="noopener noreferrer"
							target="_blank"
						>
							LinkedIn
						</a>

					</div>
				</div>
			</footer>
		</FadeIn>
	);
};

export default Footer;
