import React from 'react';
import './GradientBackground.css';

const GradientBackground = () => {
	return (
		<div className="animated-background">
			<div className="animatedwrapper">
				<div className="dot dot--one"></div>
				<div className="dot dot--two"></div>
				<div className="dot dot--three"></div>
			</div>
		</div>
		);
}

export default GradientBackground;
