import GradientBackground from "./components/GradientBackground/GradientBackground.js";
import Navigation from "./components/Navigation/Navigation";
import Landing from "./components/Landing/Landing";
import Footer from "./components/Footer/Footer";
import ProjectList from "./components/ProjectList/ProjectList";
import About from "./components/About/About";
import "./fonts/Superior-Regular.otf";
import { Routes, Route } from "react-router-dom";
import React from 'react';

import "./App.css";

function App() {
  return (
    <div className="App">
    <Navigation />
      <Routes>
        <Route exact path="/" element={<><GradientBackground /><Landing /><ProjectList /></>} />
        <Route path="/about" element={<About />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
