import React from "react";
import Logo from "./logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pdf from "../../docs/Anmol-Mathias-Resume.pdf";
import styles from "./Navigation.module.css";

const Navigation = () => {
	let location = useLocation().pathname;
	const navigate = useNavigate();
	const handleClick = (e, param) => {
		e.preventDefault();
		if (location === "/") {
			if (param === "work") {
				document
					.getElementById(param)
					.scrollIntoView({ behavior: "smooth" });
			} else {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
			}
		} else {
			navigate("/");
		}
	};

	return (
		<nav className={styles.navbar}>
			<Link to="/">
				<div
					className={styles.logoImage}
					onClick={(e) => handleClick(e, "/")}
				>
					<img src={Logo} alt="" />
				</div>
			</Link>
			<div className={styles.navlinks}>
				<a href="" className={styles.hoverLink}>
					<p
						onClick={(e) => handleClick(e, "work")}
					>
						Work
					</p>
				</a>
				<a className={styles.hoverLink} href={Pdf} target="_blank" rel="noopener noreferrer">
					<p>Resume</p>
				</a>
				<Link to="/about" className={styles.hoverLink}>
					<p>About</p>
				</Link>
			</div>
		</nav>
	);
};

export default Navigation;
