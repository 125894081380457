import React from "react";
import FadeIn from '../FadeIn/FadeIn';
import profile from "./profile.jpg";
import "./About.css";

const About = () => {
	return (
		<FadeIn>
		<div className="about-section">
			<div className="about-container">
				<div className="about-wrap">
					<h2>Why hello there!</h2>
					<div className="about-leader">
						<div className="about-personal-statement">
							<p>
								I'm a Web Developer based out of Bengaluru currently looking to join a frontend dev team.
							</p>
							<p>
								Along with interests in web technologies and developement, I dabble in typography, create color themes and schemes, answer queries on programming subreddits, and spend rather too many hours fine-tuning my main developement machine running Fedora Linux.
							</p>
							<p>
								Outside of work I enjoy travel, hiking,
								late 60's tape loops and murder mysteries. Send me an email if you wanna hire me or just reach out.
							</p>
						</div>
						<div className="about-img">
							<img src={profile} alt="" />
						</div>
					</div>
					<div className="about-highlights">
						<div className="about-highlight-grid-1">
							<h6>Locations</h6>
							<p>Gurgaon</p>
							<p>Bengaluru</p>
						</div>
						<div className="about-highlight-grid-2">
							<h6>Experience</h6>
							<p>3 years</p>
						</div>
						<div className="about-highlight-grid-3">
							<h6>Background</h6>
							<p>Computer Science</p>
						</div>
						<div className="about-highlight-grid-4">
							<h6>Current skills</h6>
							<p>ReactJS, ES6, Tachyons</p>
							<p>Node.js, PostGreSQL</p>
						</div>
						<div className="about-highlight-grid-5">
							<h6>Other skills</h6>
							<p>Linux/Unix</p>
						</div>
						<div className="about-highlight-grid-6">
							<h6>Connect</h6>
							<p>+91 7570897597</p>
							<p><a href="mailto: anmolmathias@gmail.com">anmolmathias@gmail.com</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		</FadeIn>
	);
};

export default About;
