import React from "react";
import FadeIn from "../FadeIn/FadeIn";
import MondrianImage from "./interactive-mondrian.webp"
import HSLGradientImg from "./gradient-generator.webp";
import ReactChuckImg from "./react-chuck.webp";
import RobofriendsImg from "./robofriends.webp";
import KittyImg from "./kitty.webp";
import "./ProjectList.css";

const ProjectList = () => {
	return (
		<FadeIn delay={500}>
			<div className="projects-section">
				<div className="projects-container" id="work">
					<a
						href="https://github.com/anmolmathias/interactive-mondrian"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="project-wrap">
							<div className="project-leader">
								<div className="project-title">
									<h2>Interactive Mondrian</h2>
								</div>
								<div className="project-image">
									<img src={MondrianImage} alt="" />
								</div>
							</div>
							<div className="project-info">
								<p>
									A nifty abstract art creator inspired by the
									works of the fantastic Dutch painter and art
									theoritician Piet Mondrian.
								</p>
							</div>
						</div>
					</a>
					<a
						href="https://github.com/anmolmathias/hsl-gradient-generator"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="project-wrap">
							<div className="project-leader">
								<div className="project-title">
									<h2>HSL Gradient Generator</h2>
								</div>
								<div className="project-image">
									<img src={HSLGradientImg} alt="" />
								</div>
							</div>
							<div className="project-info">
								<p>
									An opinionated generater for linear
									gradients in the HSL (Hue / Saturation /
									Lightness) color space to prevent the
									dreaded 'gray zone'
								</p>
							</div>
						</div>
					</a>
					<a
						href="https://github.com/anmolmathias/react-chuck"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="project-wrap">
							<div className="project-leader">
								<div className="project-title">
									<h2>Quipping Chuck</h2>
								</div>
								<div className="project-image">
									<img src={ReactChuckImg} alt="" />
								</div>
							</div>
							<div className="project-info">
								<p>
									Quip generator for hand curated Chuck Norris
									facts utilising the chucknorris.io JSON API
								</p>
							</div>
						</div>
					</a>
					<a
						href="https://github.com/anmolmathias/robofriends"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="project-wrap">
							<div className="project-leader">
								<div className="project-title">
									<h2>Robofriends</h2>
								</div>
								<div className="project-image">
									<img src={RobofriendsImg} alt="" />
								</div>
							</div>
							<div className="project-info">
								<p>
									App built with React to experiment with
									responsive layouts, interactive searching
									and material design
								</p>
							</div>
						</div>
					</a>
					<a
						href="https://github.com/anmolmathias/kitty-alabaster"
						target="_blank"
						rel="noopener noreferrer"
					>
						<div className="project-wrap">
							<div className="project-leader">
								<div className="project-title">
									<h2>Vim/Kitty Themes</h2>
								</div>
								<div className="project-image">
									<img src={KittyImg} alt="" />
								</div>
							</div>
							<div className="project-info">
								<p>
									A light/dark color scheme for terminal
									editors and emulators like vim and kitty
									with minimal amount of highlighting
								</p>
							</div>
						</div>
					</a>
				</div>
			</div>
		</FadeIn>
	);
};

export default ProjectList;
