import React from "react";
import FadeIn from '../FadeIn/FadeIn';
import "./Landing.css";

const Landing = () => {
	return (
		<FadeIn>
		<div className="landing-section">
			<div className="landing-container">
				<div className="landing-wrap">
					<div className="landing-heading anim">
						<h1>Hello,</h1>
						<h1>I'm Anmol</h1>
					</div>
					<div className="landing-paragraph">
						<p>
							I write code, think about data and create digital experiences.
						</p>
						<p>
							Currently experimenting with mobile-first linear user interfaces and looking to join a motivated team to grow as a developer. Check out some of my personal projects below.
						</p>
					</div>
				</div>
				<div className="landing-divider"></div>
			</div>
		</div>
		</FadeIn>
	);
};

export default Landing;
